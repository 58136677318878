export const gateway = "https://stable-wawanesalife-bms-api.link.wawanesalife.com";
export const LoginUrlParameter = "https://prod-linkadmin.auth.ca-central-1.amazoncognito.com/login?client_id=rmibn1muiv36q67obnpc8vjr5&response_type=token&scope=email+openid&redirect_uri=https%3A%2F%2Fprod-linkadmin.link.wawanesalife.com%2Fredirect";
export const HasLinkAdmin = "yes";
export const BrokerPoolClientParameter = "tbd";
export const BrokerPoolDomainParameter = "stable-brokers";
export const BrokerPoolParameter = "ca-central-1_Q5DSclAvd";

export const TopMenuLink1Caption = "Wawanesa Life";
export const TopMenuLink1Url = "https://www.wawanesalife.com";
export const TopMenuLink2Caption = "QuickTerm quoter";
export const TopMenuLink2Url = "https://quickterm.link.wawanesalife.com";

//updated on Thu Dec 12 2024 20:23:01 GMT-0600 (Central Standard Time)
